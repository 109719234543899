
































































































































































































import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import { UserDetails } from '@/types';
import MyHive from '@/components/MyHive.vue';

@Component({
  components: {
    MyHive,
  },
})
export default class AddUserDialog extends Vue {
  dialog = false

  loading = false
  valid = false

  alert = false
  error = ''

  newUser: UserDetails = {
    firstName: '',
    lastName: '',
    address: '',
    email: '',
    phone: '',
    hivesCredits: 1,
    honeycombsCredits: 0,
    hives: {},
  }

  emailRules = [
    (v: string) => !!v || "L'indirizzo email è richiesto",
    (v: string) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*\s*$/
      .test(v) || "L'indirizzo email non è un indirizzo corretto",
  ]

  get value(): boolean {
    return this.$store.getters.addUserDialog;
  }

  get form(): Vue & {
    validate: () => boolean;
    reset: () => void;
    resetValidation: () => void;
    } {
    return this.$refs.form as Vue & {
      validate: () => boolean;
      reset: () => void;
      resetValidation: () => void;
    };
  }

  get hivesCreditsText(): string {
    if (this.newUser.hivesCredits === 0) return "Non avrà fiorini d'oro";
    if (this.newUser.hivesCredits === 1) return "Avrà 1 fiorino d'oro!";
    return `Avrà ${this.newUser.hivesCredits} fiorini d'oro!`;
  }

  get hivesCreditsCaption(): string {
    if (this.newUser.hivesCredits === 0) return "Con un fiorino d'oro potrà adottare un'arnia";
    if (this.newUser.hivesCredits === 1) return "Potrà adottare un'arnia";
    return `Potrà adottare ${this.newUser.hivesCredits} arnie`;
  }

  get honeycombsCreditsText(): string {
    if (this.newUser.honeycombsCredits === 0) return "Non avrà fiorini d'argento";
    if (this.newUser.honeycombsCredits === 1) return "Avrà 1 fiorino d'argento!";
    return `Avrà ${this.newUser.honeycombsCredits} fiorini d'argento!`;
  }

  get honeycombsCreditsCaption(): string {
    if (this.newUser.honeycombsCredits === 0) return "Con un fiorini d'argento potrà ottenere il miele di un favo";
    if (this.newUser.honeycombsCredits === 1) return 'Potrà ottenere il miele di un favo';
    return `Potrà ottenere il miele di ${this.newUser.honeycombsCredits} favi`;
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    const debugPrefix = 'AddUserDialog: onValueChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    // Scrolla in cima alla finestra
    const element = document.getElementsByClassName('dialog-card')[0];
    if (element) element.scrollTop = 0;

    if (val) {
      this.newUser.firstName = '';
      this.newUser.lastName = '';
      this.newUser.address = '';
      this.newUser.email = '';
      this.newUser.phone = '';
      this.newUser.hivesCredits = 1;
      this.newUser.honeycombsCredits = 0;

      this.alert = false;
      this.error = '';

      if (this.form) {
        this.form.reset();
        // this.form.resetValidation();
      }
      this.loading = false;
    }

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    const debugPrefix = 'AddUserDialog: onDialogChanged()';
    console.log(debugPrefix); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'addUser');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  onIncrement(type: 'hivesCredits' | 'honeycombsCredits') {
    if (type === 'hivesCredits') this.$set(this.newUser, 'hivesCredits', this.newUser.hivesCredits + 1);
    if (type === 'honeycombsCredits') this.$set(this.newUser, 'honeycombsCredits', this.newUser.honeycombsCredits + 1);
  }

  onDecrement(type: 'hivesCredits' | 'honeycombsCredits') {
    if (type === 'hivesCredits'
      && this.newUser.hivesCredits > 0) this.$set(this.newUser, 'hivesCredits', this.newUser.hivesCredits - 1);
    if (type === 'honeycombsCredits'
      && this.newUser.honeycombsCredits > 0) this.$set(this.newUser, 'honeycombsCredits', this.newUser.honeycombsCredits - 1);
  }

  onCancel() {
    this.dialog = false;
  }

  async onAdd() {
    const debugPrefix = 'AddUserDialog: onAdd()';
    console.log(debugPrefix); // eslint-disable-line no-console

    const formRef = this.$refs.form;

    if ((formRef as any).validate()) {
      this.loading = true;

      try {
        await this.$store.dispatch('addUser', this.newUser);
        this.loading = false;
        this.dialog = false;
      } catch (error) {
        console.log(`${debugPrefix} - error =`, error); // eslint-disable-line no-console

        this.loading = false;

        switch (error) {
          case 'auth/invalid-email':
            this.error = "L'indirizzo email non è nel formato corretto";
            break;

          case 'auth/email-already-exists':
            this.error = "L'indirizzo email è utilizzato da un altro utente";
            break;

          default:
            this.error = `Errore sconosciuto (${error})`;
            break;
        }

        this.alert = true;
      }
    }
  }
}
